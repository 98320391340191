import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const Head = () => {
  return (
    <Seo
      title="守恒鍼灸美容館"
      description="守恒鍼灸美容館は、（株）政鍼グループとして、初めて美容室と鍼灸・エステのコラボレーションを実現した、美容と健康を1カ所に集約したサロンです。心身の健康が美容に不可欠と考え、本院の鎌田鍼灸整骨院および鍼灸エステ一花堂の三店舗で連携してお客様に美容と健康を提供しています。"
    />
  );
};

const BiyoukanPage = () => {
  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "美容館メニュー",
      link: "/biyoukan-menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "アクセス・お問い合わせ",
      link: "#access",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
  ];

  return (
    <Layout navLinks={navLinks} color={"rgb(244, 107, 166, 0.2)"}>
      <div className="image-heading">
        <StaticImage
          src="../images/biyoukan_main.png"
          width={4000}
          alt="topの画像"
        />
      </div>
      <div className="kamata-body">
        <main>
          <div id="about" className="aboutusbox">
            <h2>守恒鍼灸美容館について</h2>
            <div className="aboutbodyleft">
              守恒鍼灸美容館は、（株）政鍼グループとして初めて美容室と鍼灸整骨院・エステがコラボレーションした美容と健康を1カ所に集約したサロンです。
              <br />
              心身の健康が美容に不可欠と考え、本院の鎌田鍼灸整骨鍼灸エステ一花堂と三店舗で連携してお客様に美容と健康を提供しています。
              <br />
              お客様一人ひとりと向き合い、寄り添えるカウンセリングを行います。生活スタイルに合った美容をおすすめし、毎日が素敵に過ごせるようにご提案します。
              <br />
              （★ 当美容館は、
              <a className="text-red">ビューティーサロンあふりかぞう</a>
              に運営を委託しております）
            </div>
            <div className="to-top-box">
              <Link to="/biyoukan-menu" className="menu-button">
                守恒鍼灸美容館のメニューはこちら
              </Link>
            </div>
          </div>

          <div className="kamata-body-text">
            <div className="kamata-body-text-title">
              <h2>守恒鍼灸美容館営業スケジュール</h2>
            </div>
          </div>
          <div id="ConsultationTime" className="consultationtime">
            <table className="table-schedule">
              <tr>
                <th></th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th className="saturday">土</th>
                <th className="sunday">日</th>
              </tr>
              <tr>
                <td>10:00～19:00</td>
                <td className="closed">休</td>
                <td>○</td>
                <td>○</td>
                <td>○</td>
                <td>○</td>
                <td>○</td>
                <td>○</td>
              </tr>
            </table>
          </div>

          <div id="access" className="accessCard">
            <h3 className="accessCardHeader">アクセス・電話番号</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.039745169144!2d130.87209551529378!3d33.837085636484964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c6d5b65549f3%3A0xfcedaff42753ab85!2z5a6I5oGS6Y2854G4576O5a656aSo!5e0!3m2!1sja!2sjp!4v1671564536455!5m2!1sja!2sjp"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <table>
              <tr>
                <th>住所:</th>
                <td>福岡県北九州市小倉南区守恒本町２丁目２−２６明夏ビル1F-B</td>
              </tr>
              <tr>
                <th>アクセス:</th>
                <td>
                  守恒駅から140m (
                  競馬場前(福岡)駅から690m、徳力公団前駅から960m）
                </td>
              </tr>
              <tr>
                <th>電話番号:</th>
                <td>090-9650-2800</td>
              </tr>
            </table>
          </div>
          <div className="to-top-box">
            <Link to="/" className="menu-button">
              トップページへ戻る
            </Link>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default BiyoukanPage;
